import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Menu } from 'boss-ui';
import { useAppContext } from '../../libs/context-lib';
import { EVENT_WORDING, EVENT_DELIVERY } from '../../libs/constants';

const calculateCompoundTeamPath = (location, id) => {
  const splittedPathName = location && location.pathname.split('/');
  if (splittedPathName.includes('team')) {
    return location.pathname;
  }
  return `/event/${id}/team/join`;
};

export default function EventMenu({ onLeaveEvent = () => {}, onShowCredits = () => {}, hideScoring = false }) {
  const { event, user } = useAppContext();
  const scenarioLabel = EVENT_WORDING.SCENARIO[event.type];
  const userWithoutTeam = isEmpty(user.userInTeam);
  const isOnDemandEvent = event.delivery === EVENT_DELIVERY.ON_DEMAND;

  const getTeamMenu = (id) =>
    userWithoutTeam
      ? {
          path: (location) => calculateCompoundTeamPath(location, id),
        }
      : { path: `/event/${id}/team` };

  const getMenuItems = () => {
    const items = [
      {
        path: `/event/${event.eventId}`,
        label: scenarioLabel,
        id: 'menuScenario',
        exact: true,
      },
    ];
    // ON DEMAND EVENTS DO NOT HAVE TEAMS
    if (!isOnDemandEvent) {
      items.push({
        rootPath: `/event/${event.eventId}/team`,
        label: 'TEAM',
        id: 'menuTeam',
        ...getTeamMenu(event.eventId),
      });
    }

    // ON DEMAND EVENTS DO NOT HAVE SCORING SECTION
    if (!hideScoring && event.isEventStarted && !isOnDemandEvent) {
      items.push({
        label: 'SCORING',
        id: 'menuScoring',
        rootPath: `/event/${event.eventId}/scoring`,
        subMenu: [
          {
            path: `/event/${event.eventId}/scoring/top10`,
            label: 'Top 10 teams',
            id: 'subMenuTop10',
          },
          {
            path: `/event/${event.eventId}/scoring/top`,
            label: 'All teams score',
            id: 'subMenuAllScore',
          },
          {
            path: `/event/${event.eventId}/scoring/scoreLine`,
            label: 'Score timeline [Top 5]',
            id: 'subMenuTop5',
          },
        ],
      });
    }
    return items;
  };

  const getAuxiliaryItems = () => {
    const items = [];
    if (event.isEventRunning || !event.isEventStarted) {
      items.push({
        label: 'BACK TO HOME',
        id: 'leaveEventLinkedText',
        onClick: onLeaveEvent,
        dangerTheme: true,
      });
    }
    if (event.eventCreditsMd) {
      items.push({
        label: 'CREDITS',
        id: 'creditsLinkedText',
        onClick: onShowCredits,
      });
    }
    return items;
  };

  return <Menu menuItems={getMenuItems()} auxiliaryItems={getAuxiliaryItems()} />;
}
