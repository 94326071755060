import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Media from 'react-media';
import { Box, Flex } from 'reflexbox';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Api from '../../api';
import ContainerEvtPlayerMobile from '../ContainerEvtPlayerMobile';
import CountDown from '../CountDown';
import { FloatingPanel } from 'boss-ui';
import PointsCard from '../teams/PointsCard';
import {
  NAV_MOBILE_MAXWIDTH,
  EVENT_DELIVERY,
  DEFAULT_ON_DEMAND_EVENT_DURATION,
  ONE_MINUTE_MS,
  MAX_INT32,
} from '../../libs/constants';
import { useAppContext } from '../../libs/context-lib';
import { isTodayAfterThan, isTodayBetween } from '../../libs/dates-lib';
import { onError } from '../../libs/error-lib';

const nowDate = new Date();
const API = new Api();

export default function EventFloatingBox({ isAdmin = false, hideScoring = false }) {
  const { event, setEvent, user } = useAppContext();
  const [teamPoints, setTeamPoints] = useState(0);
  const [loadingTeamPoints, setLoadingTeamPoints] = useState(false);
  const isOnDemandEvent = useMemo(
    () => event.delivery === EVENT_DELIVERY.ON_DEMAND,
    [event.delivery]
  );
  const userWithoutTeam = useMemo(() => isEmpty(user.userInTeam), [user.userInTeam]);

  const countDownStartDate = useMemo(() => {
    if (!isOnDemandEvent) {
      return event.startDate;
    }
    if (event.participant && event.participant.joinDate) {
      return event.participant.joinDate;
    }
    return nowDate;
  }, [isOnDemandEvent, event.startDate, event.participant]);

  const countDownEndDate = useMemo(() => {
    if (!isOnDemandEvent) {
      return event.endDate;
    }
    if (event.duration) {
      if (event.participant && event.participant.joinDate) {
        const joinDate = new Date(event.participant.joinDate);
        return new Date(joinDate.getTime() + event.duration * ONE_MINUTE_MS);
      }
      return new Date(nowDate.getTime() + event.duration * ONE_MINUTE_MS);
    }
    return new Date(nowDate.getTime() + DEFAULT_ON_DEMAND_EVENT_DURATION * ONE_MINUTE_MS);
  }, [isOnDemandEvent, event.endDate, event.participant, event.duration]);

  const getPoints = useCallback(async () => {
    setLoadingTeamPoints(true);
    try {
      const rq = await API.get('events', `/events/${event.eventId}/score`);
      setTeamPoints(get(rq, 'score.total', 0));
    } catch (e) {
      onError(e);
    } finally {
      setLoadingTeamPoints(false);
    }
  }, [event.eventId]);
  const shouldRenderPointsBox = !hideScoring && (!userWithoutTeam || isOnDemandEvent);

  useEffect(() => {
    if (isOnDemandEvent || !userWithoutTeam) {
      getPoints();
    }
  }, [getPoints, isOnDemandEvent, userWithoutTeam]);

  // set isEventRunning to false in order to block some UI actions
  useEffect(() => {
    const endDateInMs = new Date(countDownEndDate).getTime() - new Date().getTime();
    let eventRunningTimeout;
    // setTimeout limit is MAX_INT32 if end date in ms is bigger avoid timeout
    if (endDateInMs < MAX_INT32) {
      eventRunningTimeout = setTimeout(() => {
        console.log(
          `EVENT-END-TIMEOUT countDownEndDate: ${countDownEndDate}, isEventRunning: false, isEventEnded: true`
        );
        setEvent((e) => ({ ...e, isEventRunning: false, isEventEnded: true }));
      }, endDateInMs);
    }
    return () => clearTimeout(eventRunningTimeout);
  }, [countDownEndDate, setEvent]);

  // set isEventStarted to true in order to show up the scoring in the menu
  useEffect(() => {
    let startEventInterval;
    const startDateInMs = new Date(countDownStartDate).getTime() - new Date().getTime();
    // setTimeout limit is MAX_INT32 if start date in ms is bigger avoid timeout
    if (startDateInMs < MAX_INT32) {
      startEventInterval = setTimeout(() => {
        console.log(
          `EVENT-START-TIMEOUT countDownStartDate: ${countDownStartDate}, countDownEndDate: ${countDownEndDate}, isEventStarted: true, isEventRunning: ${isTodayBetween(
            countDownStartDate,
            countDownEndDate
          )}, isEventEnded: ${isTodayAfterThan(countDownEndDate)}`
        );
        setEvent((e) => ({
          ...e,
          isEventStarted: true,
          isEventRunning: isTodayBetween(countDownStartDate, countDownEndDate),
          isEventEnded: isTodayAfterThan(countDownEndDate),
        }));
      }, startDateInMs);
    }
    return () => clearTimeout(startEventInterval);
  }, [countDownStartDate, countDownEndDate, setEvent]);

  useEffect(() => {
    console.log(
      `EFFECT countDownStartDate: ${countDownStartDate}, countDownEndDate: ${countDownEndDate}, isEventStarted: ${isTodayAfterThan(
        countDownStartDate
      )}, isEventRunning: ${isTodayBetween(
        countDownStartDate,
        countDownEndDate
      )}, isEventEnded: ${isTodayAfterThan(countDownEndDate)}`
    );
    setEvent((e) => ({
      ...e,
      isEventRunning: isTodayBetween(countDownStartDate, countDownEndDate),
      isEventStarted: isTodayAfterThan(countDownStartDate),
      isEventEnded: isTodayAfterThan(countDownEndDate),
    }));
  }, [countDownStartDate, countDownEndDate, setEvent]);

  return (
    <Media query={`(max-width: ${NAV_MOBILE_MAXWIDTH})`}>
      {(mobileScreen) => (
        <>
          {mobileScreen ? (
            <ContainerEvtPlayerMobile>
              <Flex pl="12px">
                <CountDown
                  isAdminView={isAdmin}
                  endDate={countDownEndDate}
                  startDate={countDownStartDate}
                  mobile
                />
              </Flex>
              <Flex style={{ minWidth: '185px' }}>
                {shouldRenderPointsBox && (
                  <PointsCard
                    showRefresh={event.isEventRunning}
                    teamPoints={teamPoints}
                    onRefresh={getPoints}
                    loading={loadingTeamPoints}
                    isOnDemandEvent={isOnDemandEvent}
                    sessionId={get(event, 'participant.sessionId')}
                    mobile
                  />
                )}
              </Flex>
            </ContainerEvtPlayerMobile>
          ) : (
            <FloatingPanel>
              <Box style={{ position: 'fixed', top: '130px', zIndex: '100' }}>
                <CountDown
                  isAdminView={isAdmin}
                  endDate={countDownEndDate}
                  startDate={countDownStartDate}
                />
                {shouldRenderPointsBox && (
                  <PointsCard
                    showRefresh={event.isEventRunning}
                    teamPoints={teamPoints}
                    onRefresh={getPoints}
                    loading={loadingTeamPoints}
                    isOnDemandEvent={isOnDemandEvent}
                    sessionId={get(event, 'participant.sessionId')}
                  />
                )}
              </Box>
            </FloatingPanel>
          )}
        </>
      )}
    </Media>
  );
}
