import { useEffect, useState } from 'react';

export const FEATURE_FLAG_ID_RESOURCE_ALLOCATION_V2 = 'RESOURCE_ALLOCATION_V2_FLAG';
export const FEATURE_FLAG_ID_HIDE_USER_SCORING = 'HIDE_USER_SCORING';


export const useGetFeatureFlag = (api, { featureFlagId }) => {
  const [loading, setLoading] = useState(true);
  const [flagConfig, setFlagConfig] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const result = await getFeatureFlag(api, featureFlagId);
      console.log(result);
      setFlagConfig(result);
      setLoading(false);
    }

    fetchData().then();
  }, [api, featureFlagId]);
  return { loading, flagConfig };
};

const assertFeatureFlagIdIsNotEmpty = (featureFlagId) => {
  if (!featureFlagId) {
    throw new Error('featureFlagId is required');
  }
};

export const getFeatureFlag = async (api, featureFlagId) => {
  assertFeatureFlagIdIsNotEmpty(featureFlagId);
  return await api.get('events', `/admin/feature-flags/${featureFlagId}`);
};

export const postFeatureFlag = async (api, { featureFlagId, configPayload }) => {
  assertFeatureFlagIdIsNotEmpty(featureFlagId);
  if (!configPayload || typeof configPayload !== 'object') {
    throw new Error('configPayload is required and must be an object');
  }
  return await api.post('events', `/admin/feature-flags/${featureFlagId}`, {
    body: configPayload
  });
};

export const getResourceAllocationV2Flag = (api) => {
  return getFeatureFlag(api, FEATURE_FLAG_ID_RESOURCE_ALLOCATION_V2);
};

export const useGetResourceAllocationV2Flag = (api) => {
  return useGetFeatureFlag(api, { featureFlagId: FEATURE_FLAG_ID_RESOURCE_ALLOCATION_V2 });
};

export const shouldHideScoringForEventId = async (api, eventId) => {
  try {
    const resp = await getFeatureFlag(api, FEATURE_FLAG_ID_HIDE_USER_SCORING);
    const eventIdsToHide = resp?.hiddenForEventIds ?? [];
    return eventIdsToHide.includes(eventId);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const useListAllEvents = (api, { pageSize = 200 } = {}) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function fetchAllEvents() {
      let lastEvaluatedKey = {};
      const retrievedEvents = [];
      let paginationHasMore = true;
      while (paginationHasMore) {
        const result = await api.get('events', `/admin/events`, {
          queryStringParameters: {
            pageSize,
            ...lastEvaluatedKey
          }
        });
        retrievedEvents.push(...result.events);
        paginationHasMore = result?.pagination?.more || false;
        lastEvaluatedKey = result.pagination.LastEvaluatedKey;
      }
      return retrievedEvents;
    }

    fetchAllEvents().then(eventsFromApi => {
      setEvents(eventsFromApi);
      setLoading(false);
    });
  }, [api, pageSize]);
  return { loading, events };
};